.carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh; 
    overflow: hidden;
    margin-bottom: 4em;
    background-color: var(--background-page);
  }
  
  .imageContainer {
    width: 100%;
    height: 100%;
  }
  
  .carouselImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .carouselButton {
    position: absolute;
    border: none;
    cursor: pointer;
    color: white;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.5rem;
    background-color: transparent;}
  
  .carouselButton.prev {
    left: 1rem;
  }
  
  .carouselButton.next {
    right: 1rem;
  }
  