:root {
  --background-page: #000;
  --text-color: #fff;
  --text-color-hover: #bbbbbb;
  --text-color-highlight: #007BFF;
  --text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4), 0px 0px 5px rgba(0, 0, 0, 0.15); 
  --neumorphism-shadow-light: rgba(255, 255, 255, 0.1);
  --neumorphism-shadow-dark: rgba(0, 0, 0, 0.7);  
}


* {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}