.homeContainer {
    background-color: var(--background-page);
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .container {
        padding: 0 0.5rem;
    }
}
