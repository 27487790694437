.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--background-page);
    margin: 0 auto;
    padding: 2rem 0;
    gap: 2rem;
    position: relative;
}

.container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    border-top: 2px solid var(--text-color-highlight);
}

.infoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--background-page);
    padding: 2rem;
}

.infoTitle {
    font-size: 2.5rem;
    text-align: center;
    color: var(--text-color);
    margin-bottom: 1rem;
}

.infoText {
    color: var(--text-color);
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.contactForm {
    flex: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    gap: 1.5rem;
    padding: 2rem;
    background-color: var(--background-page);
    border-radius: 15px;
    box-shadow: 5px 5px 15px var(--neumorphism-shadow-dark), -5px -5px 15px var(--neumorphism-shadow-light);
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.labelWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label {
    margin-bottom: 0.5rem;
    color: var(--text-color-hover);
    font-size: 1rem;
}

.error {
    color: #ff6f61; 
    font-size: 0.875rem;
    margin-left: 1rem;
}

.inputField {
    background: var(--background-page);
    border: none;
    border-radius: 10px;
    box-shadow: inset 2px 2px 5px var(--neumorphism-shadow-dark), inset -2px -2px 5px var(--neumorphism-shadow-light);
    color: var(--text-color);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    outline: none;
    transition: box-shadow 0.3s ease;
}

.inputField::placeholder {
    color: var(--text-color-hover);
}

.inputField:focus {
    box-shadow: inset 2px 2px 10px var(--neumorphism-shadow-dark), inset -2px -2px 10px var(--neumorphism-shadow-light);
}

.submitButton {
    background: var(--background-page);
    border: none;
    border-radius: 10px;
    box-shadow: 2px 2px 5px var(--neumorphism-shadow-dark), -2px -2px 5px var(--neumorphism-shadow-light);
    color: var(--text-color);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    align-self: flex-start;
    text-transform: uppercase;
}

.submitButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.submitButton:hover:enabled {
    box-shadow: 2px 2px 10px var(--neumorphism-shadow-dark), -2px -2px 10px var(--neumorphism-shadow-light);
}

.responseMessage {
    color: var(--text-color-highlight);
    text-align: center;
    margin-top: 1rem;
}

@media (max-width: 767px) {
    .container {
        flex-direction: column;
    }

    .infoContainer {
        margin-bottom: 2rem;
    }
}
