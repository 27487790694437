.footerContainer {
    position: relative;
    padding: 2em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    background: var(--background-page);
    color: var(--text-color);
  }

  .footerContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    border-top: 2px solid var(--text-color-highlight);
  }
  
  .footerText {
    margin: 1em 0;
    text-align: center;
    text-shadow: var(--text-shadow);
  }
  
  .backToTopContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
  
  .backToTop {
    color: var(--text-color);
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    margin: 1em 0;
    font-size: 1.2em;
  }
  
  .backToTop:hover {
    color: var(--text-color-highlight);
  }