.container {
  width: 100%;
  position: relative;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100vh;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-color);
  text-shadow: var(--text-shadow);
  text-align: center;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.5);
}

.title {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.titleSmall {
  font-size: 4em;
}

@media (max-width: 767px) {
  .titleSmall {
    font-size: 3em; 
  }
}

.textContainer {
  width: 100%;
  padding: 2em 5%;
  text-align: center;
  background-color: var(--background-page);
}

.paragraph {
  font-size: 2em;
  margin-bottom: 1em;
  color: var(--text-color);
  text-shadow: var(--text-shadow);
}
