.header {
  width: 100%;
  background-color: var(--background-page);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  height: 7em;
  text-shadow: var(--text-shadow);
}

.contact {
  font-size: 1.5em;
  text-shadow: var(--text-shadow);
  color: var(--text-color);
  cursor: pointer;
  transition: color 0.3s ease;
}

.contact:hover {
  color: var(--text-color-highlight);
  text-shadow: var(--text-shadow);
}

.contact:active {
  color: var(--text-color-hover);
  text-shadow: var(--text-shadow);
}
